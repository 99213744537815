export class CustomerIndex {
    constructor() {
        this.CustomerStatus  = -1;
        this.CityRegion = 0;
        this.CustomerType = 0;
        this.Group = 0;
        this.ListingRange = -1;
        this.IsWorkingUser = -1;
        this.AssigneeId = -1;
        this.InterestedProduct = -1;
    }
    FilterKeyword: string;
    AssigneeId: number;
    CityRegion: number;
    Group: number;
    CustomerType: number;
    ListingRange: number;
    CustomerStatus: number;
    CreationDateFrom: string;
    CreationDateTo: string;
    AssignFrom: string;
    AssignTo: string;
    LastUpdateDate: string;
    PageIndex: number;
    PageSize: number;
    Order: number;
    IsWorkingUser: number;
    InterestedProduct: number;
}
