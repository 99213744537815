import { SysrequestService } from './sysrequest.service';
import { Injectable } from "@angular/core";
import { OpportunitySearchModel } from '../models/opportunity-search';
import { ServerResponse } from '../models/response';
import { OpportunityDetailModel } from '../models/opportunity-detail';
import { GetListCustomerLogParam } from '../models/customerlog-getlist-param';
import { GetListPaymentParam } from '../models/payment-getlist-param';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {
  constructor(
    private sysrequest: SysrequestService,
    private configService: ConfigAssetLoaderService,
  ) { }
  initList(callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/initlist`, {}, (response: ServerResponse) => {
      callback(response);
    });
  }
  getList(model: OpportunitySearchModel, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/search `, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  getById(id: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/getbyid`, id, (response: ServerResponse) => {
      callback(response);
    });
  }
  getListByCustomerID(customerId: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/getlistbycutomerid`, customerId, (response: ServerResponse) => {
      callback(response);
    });
  }
  save(model: OpportunityDetailModel, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/save`, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  delete(id: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/delete`, id, (response: ServerResponse) => {
      callback(response);
    });
  }
  approved(id: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/approved`, id, (response: ServerResponse) => {
      callback(response);
    });
  }
  approvedByManager(id: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/approvedByManager`, id, (response: ServerResponse) => {
      callback(response);
    });
  }
  cancelled(id: number, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/cancelled`, id, (response: ServerResponse) => {
      callback(response);
    });
  }
  getLogs(pr: GetListCustomerLogParam, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/getlogs`, pr, (response: ServerResponse) => {
      callback(response);
    });
  }
  getPayments(pr: GetListPaymentParam, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/getpayments`, pr, (response: ServerResponse) => {
      callback(response);
    });
  }

  exportList(model: OpportunitySearchModel, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/exportList `, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  getNewAccountBalance(model: OpportunityDetailModel, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/opportunity/getnewaccountbalance`, model, (response: ServerResponse) => {
      callback(response);
    }, "/", false);
  }
}
