export class Package {
  constructor() { }
  Id: number;
  Name: string;
  OldPrice: number;
  Price: number;
  PriceInput: string;
  IsEditPrice: boolean;
  Discount: number;
  DiscountPercent: number;
  DiscountInput: string;
  ActualPrice: number;
  ActualPriceInput: string;
  LeadNumber: number;
  VipNumber: number;
  NormalNumber: number;
  BumpNumber: number;
  ExpiredDay: number;
  Priority: number;
  Quantity: string;
  DurationDayInput: string;
  DurationDay: number;
  ServiceId: number;
  Description: string;
}
