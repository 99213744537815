import { UserSearchResult } from './usersearchresult';
import { KeyValueObject } from './key-value';
import { Package } from './package';

export class PaymentInit {
    constructor() {
        this.AllState = [];
        this.AllCity = [];
        this.AllCustomerType = [];
        this.AllListing = [];
        this.AllState = [];
        this.AllAssignee = [];
        this.AllAdvertiseType = [];
        this.AllPackage = [];
    }
    AllState: { [key: number]: string };
    AllCity: { [key: number]: string };
    AllCustomerType: { [key: number]: string };
    AllListing: { [key: number]: string };
    AllStatus: { [key: number]: string };
    AllAssignee: UserSearchResult[];
    AllAdvertiseType: KeyValueObject[];
    AllPackage: Package[];
}
