export class PaymentInput {
  constructor() {
    this.PurchaseValue = "";
    this.LeadNumber = 0;
    this.VipNumber = 0;
    this.NormalNumber = 0;
    this.Urls = [];
    this.ImageFiles = [];
    this.Status = 0;
    this.TopUpValue = "";
  }
  Id: number;
  CustomerId: number;
  AdminId: number;
  Token: string;
  PurchaseValue: string;
  LeadNumber: number;
  VipNumber: number;
  NormalNumber: number;
  Type: number;
  Status: number;
  StatusName: string;
  TransactionCode: string;
  AdvertiseType: number;
  Discount: number;
  Note: string;
  AssigneeName: string;
  AssigneeId: number;
  RequestDate: Date;
  RequestBy: string;
  AssignTo: string;
  ConfirmDate: Date;
  ConfirmBy: string;
  PaymentDate: Date;
  PaymentTime: string;
  TransactionDate: string;
  TransactionTime: string;
  Urls: Array<string>;
  ImageFiles: Array<string>;
  TokenImage: string;
  VerifiedImageUrl: string;
  ImageName: string;
  OpportunityId: number;
  OpportunityName: string;
  CustomerName: string;
  TopUpValue: string;
}
