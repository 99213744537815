import { KPIEnum } from '../enums/kpi';
export class KPIIndex {
  ListMonth: number[];
  constructor() {
    this.ListMonth = [];
    for (let index = 1; index <= 12; index++) {
      this.ListMonth.push(index);
    }
  }
}
export class KPISearchCondition {
  // AllKPIType: [];
  PageIndex: number;
  PageSize: number;
  Type: number;
  Month: number;
  constructor() {
    this.Type = KPIEnum.Revenue;
    // var curDate = new Date();
    // this.Month = curDate.getUTCMonth() + 1;
    this.PageIndex = 1;
    this.PageSize = 15;
  }
}

export class AttendanceReportCondition {
  FromDate: string;
  ToDate: string;
  Type: number;
  PageIndex: number;
  PageSize: number;
  CityRegion: number;
  constructor() {
    this.Type = KPIEnum.Revenue;
    this.PageIndex = 1;
    this.PageSize = 15;
    this.CityRegion = 0;
  }
}
