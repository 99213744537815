export class CustomerReject {
    constructor() {
        this.IsInvalid = false;
    }
    Id: number;
    Type: string;
    Note: string;
    Token: string;
    IsInvalid: boolean;
}
