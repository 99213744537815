import { Package } from './package';

export class PaymentCreateInput {
    constructor() {
        this.ListPackage = [];
    }
    Id: number;
    CustomerId: number;
    PurchaseValue: number;
    TopUpValue: number;
    Note: string;
    PaymentDate: Date;
    PaymentTime: string;
    VerifiedImageUrl: string;
    ImageName: string;
    AdvertiseType: number;
    OpportunityId: number;
    ListPackage: Package[];
}
