import { Package } from './package';
import { PaymentNote } from './paymentnote';
import { KeyValueObject } from './key-value';
import { PurchaseHistory } from './purchase-history';

export class PaymentEditInit {
    constructor() {
        this.ListPackage = [];
    }
    AllPackageType: KeyValueObject[];
    AllStatus: KeyValueObject[];
    AllPaymentService: KeyValueObject[];
    AllPaymentNote: PaymentNote[];
    ListPackage: Package[];
    ListPurchaseHistory: PurchaseHistory[];
}
