import { UserSearchResult } from './usersearchresult';
import { KeyValueObject } from './key-value';
import { CityLocation } from './city-location';

export class CustomerInit {
  constructor() {
    this.AllState = [];
    this.AllCity = [];
    this.AllCustomerType = [];
    this.AllGroup = [];
    this.AllListing = [];
    this.AllState = [];
    this.AllAssignee = [];
    this.AllReasonBlock = [];
    this.AllKeyValueAssignee = [];
    this.AllLogType = [];
    this.ListSaleToAssignee =[];
  }
  AllState: KeyValueObject[];
  AllCity: KeyValueObject[];
  AllCustomerType: KeyValueObject[];
  AllGroup: KeyValueObject[];
  AllListing: KeyValueObject[];
  AllStatus: KeyValueObject[];
  AllReasonBlock: KeyValueObject[];
  AllAssignee: UserSearchResult[];
  ListSaleToAssignee: UserSearchResult[];
  AllRejectReason: KeyValueObject[];
  AllKeyValueAssignee: KeyValueObject[];
  AllLogType: KeyValueObject[];
}
