import { SysrequestService } from 'src/app/services/sysrequest.service';
import { HttpClient } from "@angular/common/http";
import { KPIIndex, KPISearchCondition, AttendanceReportCondition } from '../models/kpiindex';
import { ServerResponse } from '../models/response';
import { Injectable } from '@angular/core';
import { KPIDetail } from '../models/kpi-detail';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

@Injectable({
  providedIn: 'root'
})

export class KPIService {
  constructor(private sysrequest: SysrequestService, private configService: ConfigAssetLoaderService) { }

  search(model: KPISearchCondition, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/kpi/search`, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  searchAttendanceReport(model: AttendanceReportCondition, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/kpi/searchAttendanceReport`, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  dosave(model: KPIDetail[], callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/kpi/save`, model, (response: ServerResponse) => {
      callback(response);
    });
  }
  getKPIHistory(param: KPIDetail, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/kpi/gethistory`, param, (response: ServerResponse) => {
      callback(response);
    });
  }

  exportList(model: AttendanceReportCondition, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/kpi/exportList `, model, (response: ServerResponse) => {
      callback(response);
    });
  }

  init(callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/customer/init`, {}, (response: ServerResponse) => {
      callback(response);
    });
  }

  initRevenue(callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/report/initRevenue`, {}, (response: ServerResponse) => {
      callback(response);
    });
  }

  searchRevenue(model: AttendanceReportCondition, callback: (res: ServerResponse) => void) {
    this.sysrequest.sendRequest(this.configService.config.apiEndPoint + `api/report/searchRevenue`, model, (response: ServerResponse) => {
      callback(response);
    });
  }
}
